<template>
  <div class="container">
    <div class="row">
      <div class="col-12 pm-0 d-flex justify-content-between flex-wrap">
        <div class="d-flex">
          <FormInput type="search" id="search" placeholder="اللون" input-class="max-w-200px" @changed="searchChanged" />
          <button class="btn btn-info btn-sm rounded-0" @click="filter">
            <tooltip title="بحث">
              <i class="fa fa-search"></i>
            </tooltip>
          </button>
        </div>

        <modal-btn :target="`#${storeId}`" btn-class="btn-info btn-sm">
          <span>إضافة لون</span>&nbsp;
          <i class="fa fa-plus"></i>
        </modal-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover" :class="{'shadow-sm': !emptyList}">
            <thead>
            <tr class="bg-bb">
              <th>اللون</th>
              <th></th>
            </tr>
            </thead>
            <tbody class="bg-light">
            <tr v-if="emptyList">
              <td colspan="4" class="font-weight-bolder">لا يوجد</td>
            </tr>

            <ColorItem
              v-else
              v-for="color in list"
              :key="color"
              :color="color"
              @updated="updated"
              @deleted="deleted"
            />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>

    <teleport to="#app">
      <confirm-model
        :target-id="storeId"
        title="إضافة مركبة"
        confirm-text="تعديل"
        form="add-color">
        <form @submit.prevent="store" id="add-color">
          <div class="form-group">
            <label>اللون</label>
            <FormInput id="name" placeholder="اللون" />
          </div>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import FormInput from "@/components/Forms/FormInput"
import Tooltip from "@/components/UI/Tooltip"
import ModalBtn from "@/components/UI/ModalBtn"
import pagination from "@/mixins/pagination"
import ThePaginator from "@/components/UI/ThePaginator";
import ConfirmModel from "@/components/UI/ConfirmModel";
import Storage from "@/common/Storage";
import ColorItem from "@/components/Admin/ColorItem";

export default {
  name: "CarColors",
  components: {ColorItem, ConfirmModel, ThePaginator, ModalBtn, Tooltip, FormInput},
  mixins: [pagination],
  data() {
    return {
      search: ''
    }
  },
  computed: {
    storeId() {
      return 'add-color-modal'
    },
    defaultUrl() {
      return 'colors'
    }
  },
  methods: {
    searchChanged(event) {
      this.search = !!event ? event.target.value : ''
    },
    async filter() {
      const response = await http.send(this.defaultUrl, {_method: 'GET', search: this.search})

      if (http.responseAccepted(response)) {
        this.setPagination(response.data)
      }
    },
    async store(e) {
      const formData = new FormData(e.target)

      if (!formData.get('name')) {
        return http.errorMessage('يرجى كتابة اللون')
      }

      const response = await http.send(this.defaultUrl, formData)
      if (http.responseAccepted(response, 'color')) {
        this.updated(response.data.color)

        http.successMessage(messages.saved)
        this.hideModal(`#${this.storeId}`)

        Storage.remove('colors')
        $_('#name').val('')
      }
    }
  },
  mounted() {
    this.setList()
  }
}
</script>