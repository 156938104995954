<template>
  <tr v-if="!!color" id="rowId">
    <td class="w-50">{{ color.name }}</td>
    <td class="w-50">
      <modal-btn btn-class="btn-sm btn-primary mr-1" :target="`#${editId}`">تعديل</modal-btn>
      <modal-btn btn-class="btn-sm btn-soft-danger" :target="`#${deleteId}`">حذف</modal-btn>
    </td>
    <teleport to="#app">
      <confirm-model
        :target-id="editId"
        title="تعديل مركبة"
        confirm-text="تعديل"
        :form="`edit-form-${color.id}`">
        <form @submit.prevent="update" :id="`edit-form-${color.id}`">
          <div class="form-group">
            <label>إسم المركبة</label>
            <FormInput
              :id="`name${color.id}`"
              name="name"
              :value="color.name"
              placeholder="اللون"
            />
          </div>
        </form>
      </confirm-model>
      <items-destroyer
        :target-id="deleteId"
        :url="`/colors/${color.id}`"
        :row-id="rowId"
        @deleted="deleted">
        <span>هل أنت متأكد من حذف</span><span class="link">&nbsp;{{ color.name }}&nbsp;</span> ؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn"
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import Storage from "@/common/Storage";

export default {
  name: "ColorItem",
  components: {ItemsDestroyer, FormInput, ConfirmModel, ModalBtn},
  emits: ['updated', 'deleted'],
  props: {
    color: {required: true, type: Object}
  },
  computed: {
    editId() {
      if (!this.color) {
        return ''
      }

      return `edit-modal-${this.color.id}`
    },
    deleteId() {
      if (!this.color) {
        return ''
      }

      return `delete-modal-${this.color.id}`
    },
    rowId() {
      if (!this.color) {
        return ''
      }

      return `color-row-${this.color.id}`
    },
    updateUrl() {
      return this.color ? `colors/${this.color.id}` : ''
    }
  },
  methods: {
    async update(e) {
      http.inputsId = this.color.id;
      const formData = new FormData(e.target)

      if (!formData.get('name')) {
        return http.errorMessage('يرجى كتابة اللون')
      }

      formData.set('_method', 'PUT')

      const response = await http.send(this.updateUrl, formData)
      if (http.responseAccepted(response)) {
        this.$emit('updated', response.data.color)

        http.successMessage(messages.saved)
        this.hideModal(`#${this.editId}`)

        Storage.remove('colors')
        $_('#name').val('')
        http.inputsId = '';
      }
    },
    deleted(id) {
      this.$emit('deleted', id)
    }
  }
}
</script>